import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const GigTemplate = ({
  content,
  contentComponent,
  title,
  venuename,
  venueaddress,
  venuewebsite,
  ticketprice,
  ticketlink,
  facebookevent,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div className="columns">
              <div className="column is-9">
                <PostContent content={content} />
              </div>
              <div classname="column is-3">
                <a className="button is-primary is-large" href={ticketlink}>Buy tickets - £{ticketprice}</a>

                <p>
                  {venuename}
                </p>
                <p>
                  {venueaddress}
                </p>
                <p>
                  {venuewebsite}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

GigTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const Gig = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <GigTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        venuename={post.frontmatter.venuename}
        venueaddress={post.frontmatter.venueaddress}
        venuewebsite={post.frontmatter.venuewebsite}
        ticketprice={post.frontmatter.ticketprice}
        ticketlink={post.frontmatter.ticketlink}
        facebookevent={post.frontmatter.facebookevent}
      />
    </Layout>
  )
}

Gig.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Gig

export const pageQuery = graphql`
  query GigByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
`
